import classnames from "classnames";
import { Field, FieldHookConfig, isFunction, useField } from "formik";
import React, { InputHTMLAttributes } from 'react';
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import SingleSelectInput from "../Input/SingleSelect";

interface FormikFormGroupProps {
  label?: string,
  errorMsg?: string;
  children: React.ReactNode
  side?: 'left' | 'right';
  isRequired?: boolean;
  isValid?: boolean;
  hasError?: boolean;
  className?: string;
  isDisabled?: boolean;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const FormikFormGroup: React.FC<FormikFormGroupProps> = ({ className, isDisabled, isValid, isRequired, label = "", hasError, errorMsg = "", side = 'right', children }) => {
  const { t } = useTranslation();
  return (
    <div className={classnames("input", className)}>
      <div className={classnames("input-wrapper", { "invalid": hasError, "valid": isValid, "disabled": isDisabled })}>
        {side === 'left' && children}
        {label && <label className={classnames("label label--floating")}>
          {t(label)} {isRequired && "*"}
        </label >}
        {side === 'right' && children}
      </div>
      {hasError && <div className="input-error">{t(errorMsg)}</div>}
    </div>
  );
};

const RadioInput: React.FC<InputProps & FieldHookConfig<any> & { side?: 'left' | 'right' }> = ({ label, placeholder, className, side, onChange, ...rest }) => {

  const [field, meta] = useField<any>(rest);
  const handleChange = (event: any) => {
    field.onChange(event);
    if (isFunction(onChange)) onChange(event.target.value)
  }

  return (
    <FormikFormGroup label={label} hasError={meta.touched && !!meta.error} errorMsg={meta.error} side={side} isRequired={!!rest?.["aria-required"]}>
      <Field
        className={classnames({ error: meta.touched && meta.error }, className)}
        value={field.value}
        placeholder={placeholder}
        type="radio"
        onChange={handleChange}
        {...rest}
      />
    </FormikFormGroup>
  )
}

const TextInput: React.FC<InputProps & FieldHookConfig<string>> = ({ label, placeholder = " ", className, onChange, ...rest }) => {
  const [field, meta] = useField<any>(rest);
  const { t } = useTranslation();
  const handleChange = (event: any) => {
    field.onChange(event);
    if (isFunction(onChange)) onChange(event.target.value);
  };

  return (
    <FormikFormGroup className={className} isDisabled={rest.disabled} isValid={field.value !== "" && meta.touched} label={label} hasError={meta.touched && !!meta.error} errorMsg={meta.error} isRequired={!!rest?.["aria-required"]}>
      <Field
        className={classnames("", { error: meta.touched && meta.error }, className)}
        value={field.value}
        placeholder={t(placeholder)}
        type="search"
        onChange={handleChange}
        as={'input'}
        {...rest}
      />
    </FormikFormGroup>
  );
};

const TextAreaInput: React.FC<InputProps & FieldHookConfig<string>> = ({ label, placeholder = " ", className, onChange, ...rest }) => {
  const [field, meta] = useField<any>(rest);
  const { t } = useTranslation();
  const handleChange = (event: any) => {
    field.onChange(event);
    if (isFunction(onChange)) onChange(event.target.value);
  };

  return (
    <FormikFormGroup className={className} isDisabled={rest.disabled} isValid={field.value !== "" && meta.touched} label={label} hasError={meta.touched && !!meta.error} errorMsg={meta.error} isRequired={!!rest?.["aria-required"]}>
      <Field
        className={classnames("", { error: meta.touched && meta.error }, className)}
        value={field.value}
        placeholder={t(placeholder)}
        onChange={handleChange}
        as={'textarea'}
        {...rest}
      />
    </FormikFormGroup>
  );
}

const PhoneValidInput: React.FC<InputProps & FieldHookConfig<any>> = ({
  label = "",
  placeholder = " ",
  className,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(rest);

  const handleChange = (newValue: any) => {
    helpers.setValue(newValue);

    if (isFunction(onChange)) onChange(newValue);
  };

  return (
    <FormikFormGroup label={label} hasError={meta.touched && !!meta.error} errorMsg={meta.error} isRequired={!!rest?.["aria-required"]}>
      <PhoneInput
        // className="phone-input"
        className={classnames("phone-input", { error: meta.touched && meta.error }, className)}
        name={field.name}
        value={field.value}
        placeholder={t(placeholder)}
        onChange={handleChange}
      />
      {/* {meta.touched && meta.error && (
        <div className="form-control-error my-1">{t(meta.error)}</div>
      )} */}
    </FormikFormGroup>
  );
};

const SelectInput: React.FC<InputProps & FieldHookConfig<any> & {
  options: any[];
  placeholder?: string;
}> = ({
  label = "",
  placeholder = " ",
  className,
  onChange,
  options = [],
  ...rest
}) => {
    const { t, i18n } = useTranslation();
    const [field, meta, helpers] = useField(rest);

    const handleChange = (newValue: any) => {
      helpers.setValue(newValue);

      if (isFunction(onChange)) onChange(newValue);
    };

    const constructOptions = (options: { value: string; labels: Record<string, string> }[]) => {
      return options.map((option) => ({ value: option.value, label: option.labels?.[i18n.language] || "" }))
    };

    return <FormikFormGroup label={label} isDisabled={rest.disabled} hasError={meta.touched && !!meta.error} errorMsg={meta.error} isRequired={!!rest?.["aria-required"]}>
      <SingleSelectInput
        className={classnames({ error: meta.touched && meta.error }, className, "w-full")}
        classNamePrefix={'react-select'}
        value={field.value}
        options={constructOptions(options)}
        placeholder={t(placeholder)}
        onSelect={handleChange}
        unstyled={true}
        isDisabled={rest.disabled}
        {...rest}
      />
    </FormikFormGroup>
  }

export { TextInput, RadioInput, PhoneValidInput, SelectInput, TextAreaInput };