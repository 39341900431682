import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { constructImageSizesObject } from "../../helpers/utils";
import { TenancyState } from "../../models/Tenancy";
import { createAppAsyncThunk } from "../extras";


export const retrieveTenancyConfig = createAppAsyncThunk(
  "tenancy/retrieveConfig",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.EligoService.getTenancyConfig();
      return {
        logos: constructImageSizesObject(response.logo),
        banners: constructImageSizesObject(response.banner),
        companyName: response.title
      }
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
)

const initialState: TenancyState = {
  logo: "",
  banner: "",
  companyName: "",
  initialized: false,
  logoOptions: undefined,
  bannerOptions: undefined
}

export const tenancySlice = createSlice({
  name: 'tenancy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /* --- Retrieve Config  ---- */
      .addCase(retrieveTenancyConfig.fulfilled, (state, action) => {
        state.logo = action.payload.logos?.['original'] || "";
        state.banner = action.payload.banners?.['original'] || "";
        state.logoOptions = action.payload.logos;
        state.bannerOptions = action.payload.banners;
        state.companyName = action.payload.companyName;
        state.initialized = true;
      })
  }
})
export default tenancySlice.reducer