import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isFunction } from "formik";
import Select from "react-select";

const toHashMap = (options: any) =>
  options.reduce((acc: any, data: any) => ({
    ...acc,
    [data.value]: data
  }), {});

// interface PropTypes {
//   value: any;
//   options: any[];
//   translate?: boolean;
//   onSelect: (a: any) => void;
// }
// TODO: correct types

const SingleSelectInput: React.FC<any> = ({
  value,
  options,
  translate = true,
  onSelect,
  ...rest
}) => {
  const { t } = useTranslation();
  const translatedOptions = useMemo(
    () =>
      translate ? options.map((o: any) => ({ ...o, label: t(o.label) })) : options,
    [options, translate, t]
  );
  const optionsHashMap = useMemo(
    () => toHashMap(translatedOptions),
    [translatedOptions]
  );
  const selectedValue = useMemo(
    () => optionsHashMap[value],
    [value, optionsHashMap]
  );

  const handleChange = (e: any) => {
    if (isFunction(onSelect)) onSelect(e.value);
  };

  return (
    <Select
      value={selectedValue ?? ""}
      options={translatedOptions}
      blurInputOnSelect
      closeMenuOnSelect
      onChange={handleChange}
      {...rest}
    />
  );
};

export default SingleSelectInput;
