export const isValidUrl = (urlStr: string): boolean => {
  try {
    return Boolean(new URL(urlStr));
  } catch (error) {
    return false;
  }
}

export const constructImageSizesObject = (images: {
  filename: string;
  sizes: Record<string | number, { localName: string, status: string, url: string }>
}): Record<string, string> | undefined => {
  if (!images) return undefined;
  return Object.entries(images.sizes)
    .filter((item) => isValidUrl(item[1].url))
    .map((item) => ({ key: item[0], url: item[1].url }))
    .reduce((acc, val) => ({ ...acc, [val.key]: val.url }), {});
};