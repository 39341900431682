import { AxiosRequestHeaders } from "axios";
import { v4 as uuidv4 } from "uuid";
import { api, applicationId } from "../common/appSettings";
import i18n, { defaultLang } from "../i18n";

export const SERVER_URL: string = `${api.protocol}://${api.host}${!!api.port ? `:${api.port}` : ""}`;

let store: any;

export const injectStore = (_store: any) => {
  store = _store;
}

export const constructRequestHeaders = (): AxiosRequestHeaders => {
  const headers: AxiosRequestHeaders = {};

  if (store) {
    const authToken = store.getState().auth.accessToken;
    if (authToken) headers.Authorization = `Bearer ${authToken}`;
  }

  headers['x-application-id'] = applicationId;
  headers['x-request-id'] = uuidv4();
  headers['Accept-Language'] = i18n.language || defaultLang;

  return headers;
}