import React from 'react';
import { applicationCopyright } from '../../common/appSettings';
import LanguageSwitch from '../LanguageSwitch';

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="flex flex-row justify-between">
        <div className="my-auto text-white">{applicationCopyright}</div>
        <LanguageSwitch />
      </div>
    </footer>
  )
};
export default Footer;