import classnames from "classnames";
import React from 'react';

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  className,
  type = 'button',
  ...rest
}) => {
  return (
    <button
      className={classnames("btn", className)}
      type={type}
      {...rest}
    >
      {children}
    </button>
  )
}
export default Button;