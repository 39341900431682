import { StripePaymentPayload } from "../api/EligoService";
import { applicationSandbox } from "../common/appSettings";
import i18n from "../i18n";
import { CurrencyType } from "../models/Product";

interface PaymentProps {
  amount: number;
  currencyType: CurrencyType;
  orderId: string;
}

export const constructStripePayload = ({ amount, currencyType, orderId }: PaymentProps) => {
  const result: StripePaymentPayload = {
    "externalId": "0000",
    "description": i18n.t("LABELS.TOTAL"),
    "type": "ecommerce",
    "sandbox": applicationSandbox,
    "amount": Math.ceil(amount * 100),
    "currency": currencyType,
    "orderId": orderId
  }
  return result;
}