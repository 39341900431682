import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { constructStripePayload } from '../helpers/payment';
import { useAppDispatch, useAppSelector } from '../store';
import { setLoading } from '../store/features/loading';
import { payWithStripe } from '../store/features/payment';

export const usePay = () => {
  const transactionId = useAppSelector((state) => state.cart.transactionId);
  const cartTotal = useAppSelector((state) => state.cart.total);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const makeStripePayment = React.useCallback(async () => {
    try {
      dispatch(setLoading(true));

      await dispatch(payWithStripe(constructStripePayload({ orderId: transactionId, amount: cartTotal, currencyType: 'EUR' }))).unwrap().then((res) => {
        window.location.href = res.checkoutUrl;
      });

    } catch (error) {
      toast.error(t('ERROR.PAYMENT.GENERIC'));
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch, t, cartTotal, transactionId]);

  return { makeStripePayment }
}