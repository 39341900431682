import React from 'react';
import { useLocation } from 'react-router-dom';
import Typography from '../../components/Typography';
import { useAppSelector } from '../../store';

const SuccessPage: React.FC = () => {
  const { search } = useLocation();
  const paymentId = new URLSearchParams(search).get('session_id');
  const { successUrl, sourceUrl, provider } = useAppSelector((state) => state.cart);

  const finalUrl = React.useMemo(() => {
    if (!decodeURIComponent(successUrl) || !provider || !paymentId) return "";
    return decodeURIComponent(successUrl) + `${provider === 'local' ? '?' : '&'}payment=${paymentId}`
  }, [successUrl, provider, paymentId]);

  React.useEffect(() => {
    if (!finalUrl) return;
    const timeoutId = setTimeout(() => {
      window.location.href = finalUrl;
    }, 250);

    return () => {
      clearTimeout(timeoutId);
    }
  }, [finalUrl]);

  return (
    <div className="flex flex-col w-full">
      <div className="lg:w-1/4 w-5/6 mx-auto mt-10 border border-corduroy-200 rounded px-6 py-4 mb-4 text-center">

        <Typography Variant="h2" className="font-semibold" text={!!finalUrl ? "LABELS.REDIRECT" : "ERROR.GENERIC"} />

        {!!finalUrl && <a className="text-primary-500" href={finalUrl}>
          {sourceUrl}
        </a>}

      </div>
    </div>
  )
}
export default SuccessPage;