import React from 'react';
import { useDispatch } from 'react-redux';
import PaymentInfo from '../view/PaymentInfo';
import SummaryInfo from '../view/SummaryInfo';
import { useAppSelector } from '../store';
import { initWizard } from '../store/features/form';
import ShippingInfoView from '../view/ShippingInfo';
import { formSteps } from '../constants/form';

const PaymentForm: React.FC = () => {
  const dispatch = useDispatch();
  const step = useAppSelector((state) => state.form.step);

  React.useEffect(() => {
    dispatch(initWizard(null));
  }, [dispatch]);

  return (
    <div className="payment-form">
      {step === formSteps.SHIPPING && <ShippingInfoView />}
      {step === formSteps.PAYMENT && <PaymentInfo />}
      {step === formSteps.SUMMARY && <SummaryInfo />}
    </div>
  )
}
export default PaymentForm;