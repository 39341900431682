import React from 'react';
import { useAppSelector } from '../../store';


const SummaryShippingVeritifation = () => {
  const { firstname, lastname, telephone, address, email } = useAppSelector((state) => state.form.shippingDetails);


  const fullName = [firstname || "", lastname || ""].join(" ");
  const fullAddress = Object.values(address || {}).filter((val) => !!val).join(', ');

  return (
    <div className="vertification-fields">
      <span>{fullName}</span>
      <span>{fullAddress}</span>
      <span>{telephone}</span>
      <span>{email}</span>
    </div>
  )
}
export default SummaryShippingVeritifation;