import { ShippingDetails } from "../models/ShippingDetails";

export const ShippingInfoModel: ShippingDetails = Object.freeze({
  firstname: "",
  lastname: "",
  email: "",
  telephone: "",
  address: ({
    country: "",
    city: "",
    province: "",
    zipcode: "",
    street: "",
    street2: "",
    hasStates: false,
  }),
  notes: ""
})

export const initialShippingInfo = Object.freeze({
  firstname: "",
  lastname: "",
  email: "",
  telephone: "",
  address: ({
    country: "",
    province: "",
    city: "",
    zipcode: "",
    street: "",
    street2: "",
  }),
  notes: ""
})