import React from 'react';
import { SelectInput, TextInput } from '../Forms/FormikInputs';

interface PropTypes {
  countryOptions?: { value: string, labels: Record<string, string>; }[];
  provinceOptions?: { value: string, labels: Record<string, string>; }[];
  onCountryChange: (countryCode: any) => void;
}

const AddressFields: React.FC<PropTypes> = ({ countryOptions = [], provinceOptions = [], onCountryChange }) => {
  return (
    <React.Fragment>

      <div className="grid grid-cols-1 md:grid-cols-5 gap-0 md:gap-4">

        <TextInput
          className="md:col-span-2"
          name="address.city"
          label="FORM.CITY"
          aria-required
        />

        <TextInput
          className="md:col-span-2"
          name="address.street"
          label="FORM.STREET"
          aria-required
        />

        <TextInput
          name="address.zipcode"
          label="FORM.ZIPCODE"
          aria-required
        />

      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4">
        <SelectInput
          className={""}
          name="address.country"
          label="FORM.COUNTRY"
          options={countryOptions}
          onChange={onCountryChange}
          aria-required
        />

        <SelectInput
          disabled={provinceOptions.length < 1}
          name="address.province"
          label="FORM.PROVINCE"
          options={provinceOptions}
          aria-required
        />
      </div>


    </React.Fragment>
  )
}
export default AddressFields;