import { defaultLang } from "../i18n";
import { Country, Province } from "../models/ShippingDetails";

export const constructCountrydata = (data: Country[]) => {

  return data.map((country) => ({
    value: country.id,
    labels: {
      [defaultLang]: country.full_name_locale,
      en: country.full_name_english
    }
  }));
}
export const constructProvincedata = (data: Province[]) => {
  // NOTE: SADLY MAGENTO DOESN'T RETURN REGION NAMES IN OTHER LANGUAGES OTHER THAN SET LOCALE
  return data.map((province) => ({
    value: province.name,
    labels: {
      [defaultLang]: province.name,
      en: province.name
    }
  }));
}