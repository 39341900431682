import React from 'react'

interface PropTypes {
  children?: React.ReactNode;
  onClick?: () => void;
}
const PanelActionable: React.FC<PropTypes> = ({ children, onClick }) => {
  return (
    <div className="mx-1 text-lg" role="button" onClick={onClick}>
      {children}
    </div>
  )
}
export default PanelActionable;