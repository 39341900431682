import React from 'react';
import { useAppSelector } from '../../../store';
import GenericError from '../../../components/GenericError';
import PaymentForm from '../../../components/PaymentForm';
import { useEligoProviderInit } from '../../../hooks/Eligo/useEligoInit';
import MetaUpdater from '../../../components/Tenancy/MetaUpdater';

const EligoProviderPage: React.FC = () => {
  const orderState = useAppSelector((state) => state.cart.orderState);
  const initialize = useEligoProviderInit();

  React.useEffect(() => {
    initialize();
  }, [initialize]);

  if (orderState.status === 'initialized') return <React.Fragment>
    <MetaUpdater />
    <PaymentForm />;
  </React.Fragment>

  if (orderState.status === 'initializing') return null;

  return <GenericError error={orderState?.type} />
}

export default EligoProviderPage;