import { Helmet } from 'react-helmet-async';
import { useAppSelector } from '../../store';

// TODO: could be taken directly from HTML HEAD
const metaDefaults = {
  title: "Eligo Payment",
  logos: {
    "32": "/logo-32x32.png",
    "192": "/logo-192x192.png",
    "180": "/logo-180x180.png"
  },
  description: "Your transaction towards Eligo"
}

const MetaUpdater = () => {
  const logo = useAppSelector((state) => state.tenancy.logo);
  const companyName = useAppSelector((state) => state.tenancy.companyName);

  return (
    <Helmet>
      <title>{companyName || metaDefaults.title}</title>
      <link rel="icon" href={logo || metaDefaults.logos['32']} sizes="32x32" data-rh="true" />
      <link rel="icon" href={logo || metaDefaults.logos['192']} sizes="192x192" data-rh="true" />
      <link rel="apple-touch-icon" href={logo || metaDefaults.logos['180']} data-rh="true" />
      <meta name="description" content={companyName || metaDefaults.description} />
    </Helmet>
  )
}
export default MetaUpdater;