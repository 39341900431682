import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthLayout from '../layouts/auth';
import MainLayout from '../layouts/main';
import CancelPage from '../pages/Cancel';
import EligoProviderEntryPoint from '../pages/Providers/Eligo';
import PageNotFound from '../pages/404';
import SuccessPage from '../pages/Success';

export const MainRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route element={<AuthLayout />}>
          <Route path="el/:transactionId" element={<EligoProviderEntryPoint />} />
        </Route>
      </Route>
      <Route path="success" element={<SuccessPage />} />
      <Route path="cancel" element={<CancelPage />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}