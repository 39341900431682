import axios, { AxiosResponse } from 'axios';
import { EligoOrder } from '../models/Order';
import { CurrencyType } from '../models/Product';
import { ProviderLoginPayload } from '../models/providers';
import { Country, Province, ShippingDetails } from '../models/ShippingDetails';
import { constructRequestHeaders, SERVER_URL } from './helpers';

export interface StripePaymentPayload {
  externalId: string,
  description: string,
  type: string,
  amount: number,
  currency: CurrencyType,
  sandbox: boolean,
  orderId?: string
}

export default class EligoService {
  baseUrl = '';

  constructor(url?: string) {
    this.baseUrl = url || `${SERVER_URL}/`;
  }
  async loginUser(payload: ProviderLoginPayload): Promise<string> {
    try {
      const response: AxiosResponse<{ data: { token: string }, meta: {} }> = await axios.post(`${this.baseUrl}v1/auth/login`, payload, { headers: constructRequestHeaders() });
      return await Promise.resolve(response.data.data.token);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async updateUser(userId: string, payload: { email: string }): Promise<any> {
    try {
      const response: AxiosResponse<{ data: any, meta: {} }> = await axios.put(`${this.baseUrl}v1/users/${userId}`, payload, { headers: constructRequestHeaders() });
      return await Promise.resolve(response.data.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async proceedToStripe(payload: StripePaymentPayload): Promise<any> {
    try {
      const response = await axios.post(`${this.baseUrl}v1/payment/stripe`, payload, { headers: constructRequestHeaders() });
      return await Promise.resolve(response.data.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async retrieveOrder({ orderId }: { orderId: string }): Promise<any> {
    try {
      const response = await axios.get(`${this.baseUrl}v1/orders/${orderId}`, { headers: constructRequestHeaders() });
      return await Promise.resolve(response.data.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async updateShippingInfo({ orderId, payload }: { orderId: string, payload: ShippingDetails }): Promise<EligoOrder> {
    try {
      const response = await axios.post(`${this.baseUrl}v1/orders/${orderId}/shippinginfo`, payload, { headers: constructRequestHeaders() })
      return await Promise.resolve(response.data.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async getCountries(): Promise<Country[]> {
    try {
      const response: AxiosResponse<{ data: Country[] }> = await axios.get(`${this.baseUrl}v1/retrieveOptions/location`, { headers: constructRequestHeaders() });
      return await Promise.resolve(response.data.data)
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async getProvinces(countryCode: string): Promise<Province[]> {
    try {
      const response: AxiosResponse<{ data: Province[] }> = await axios.get(`${this.baseUrl}v1/retrieveOptions/location?countryCode=${countryCode}`, { headers: constructRequestHeaders() });
      return await Promise.resolve(response.data.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async getTenancyConfig(): Promise<any> {
    try {
      const response: AxiosResponse<{ data: any }> = await axios.get(`${this.baseUrl}v1/config`, { headers: constructRequestHeaders() });
      return await Promise.resolve(response.data.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}