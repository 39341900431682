import React from 'react';
import CompanyLogo from '../Tenancy/Logo';


const Navbar: React.FC = () => {
  return (
    <header className="circles-background--header">
      <CompanyLogo className="logo" skeletonClassName="logo h-20 w-20" />
    </header>
  )
}
export default Navbar;