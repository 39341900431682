import React from 'react';
import { useAppDispatch } from '../../store';
import { toast } from 'react-toastify';
import { setLoading } from '../../store/features/loading';
import { getCountries, getProvinces } from '../../store/features/form';
import { getEligoOrder } from '../../store/features/cart';
import { useTranslation } from 'react-i18next';
import { retrieveTenancyConfig } from '../../store/features/tenancy';

export const useEligoProviderInit = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return React.useCallback(async () => {
    try {
      dispatch(setLoading(true));

      await dispatch(getCountries()).unwrap();

      await dispatch(getEligoOrder()).unwrap().then(async (res) => {
        if (res?.shippingInfo?.address?.country) await dispatch(getProvinces({ countryCode: res.shippingInfo.address.country })).unwrap();
      })

      await dispatch(retrieveTenancyConfig()).unwrap();

    } catch (error) {
      toast.error(t('ERROR.APP.INIT'));
    } finally {
      dispatch(setLoading(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

}
