import React from 'react';
import { PhoneValidInput, TextInput } from '../Forms/FormikInputs';


const CustomerFields: React.FC = () => {
  return (
    <React.Fragment>
      <div className="columns-1 md:columns-2">
        <TextInput
          name="firstname"
          label="FORM.FIRSTNAME"
          aria-required
        />

        <TextInput
          name="lastname"
          label="FORM.LASTNAME"
          aria-required
        />
      </div>

      <div className="columns-1 md:columns-2">
        <TextInput
          name="email"
          label="FORM.EMAIL"
          aria-required
        />

        <PhoneValidInput
          name="telephone"
          label="FORM.PHONE"
          aria-required
        />
      </div>

    </React.Fragment>
  )
}
export default CustomerFields;