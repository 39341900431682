import React from 'react';
import notfound from '../../assets/notfound.png';
import { applicationCopyright } from '../../common/appSettings';
import Typography from '../../components/Typography';

const PageNotFound: React.FC = () => {
  return (
    <div className="md:p-10 p-5 mx-auto md:mt-16 md:w-3/4 lg:w-2/4 xl:w-2/6  w-full
       flex flex-col lg:grow-0 md:grow-0 grow h-100 lg:rounded-lg">
      <h1 className="self-center text-3xl font-bold mt-5">
        <span className="text-primary-500">4</span>
        <span className="mx-3">0</span>
        <span className="text-primary-500">4</span>
      </h1>
      <Typography Variant="h1" text="LABELS.PAGE_NOT_FOUND" className="self-center text-xl mt-5" />
      <img src={notfound} alt="" />
      {/* TODO: find how to handle translation  props in Typography component */}
      <p className="my-1 text-sm text-corduroy-500">
        <Typography text="LABELS.VERSION" />
        {" "}<span className="text-black">{applicationCopyright}</span></p>
    </div>
  )
}
export default PageNotFound;