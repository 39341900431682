import EligoService from "./EligoService";

interface ApiObject {
  EligoService: EligoService;
}

const api: ApiObject = {
  EligoService: new EligoService(),
}

export default api;