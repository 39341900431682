import React from 'react';
import Typography from './Typography';

interface PropTypes {
  error?: 'paid' | 'fulfilled' | 'expired' | 'generic';
}

const GenericError: React.FC<PropTypes> = ({ error }) => {

  let message: string;
  switch (error) {
    case 'paid':
      message = 'ERROR.ORDER.PAID_ERROR';
      break;
    case 'fulfilled':
      message = 'ERROR.ORDER.FULFILLED_ERROR';
      break;
    case 'expired':
      message = 'ERROR.ORDER.EXPIRED_ERROR';
      break;

    default:
      message = 'ERROR.GENERIC';
      break;
  }

  return (
    <div className="flex flex-col w-full">
      <div className="lg:w-1/4 w-5/6 mx-auto mt-10 border border-corduroy-200 rounded px-6 py-4 mb-4 text-center">
        <Typography text={message} Variant="h2" className="font-semibold" />
        <Typography text="LABELS.CLOSE_TAB_SAFE" Variant="p" className="my-4" />
      </div>
    </div>
  )
}
export default GenericError;