import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { orderStatus } from "../../constants/order";
import { CartState } from "../../models/cart";
import { CartProduct } from "../../models/Product";
import { createAppAsyncThunk } from "../extras";
import { payWithStripe } from "./payment";


export const getEligoOrder = createAppAsyncThunk(
  "cart/getEligoOrder",
  async (_, { rejectWithValue, getState }) => {
    try {
      const orderId = getState().cart.transactionId;
      const response: any = await api.EligoService.retrieveOrder({ orderId })
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected);
    }
  }
);

const initialState: CartState = {
  sourceUrl: '',
  successUrl: '',
  cancelUrl: '',
  transactionId: '',
  paymentId: '',
  cartData: [],
  itemCount: 0,
  total: 0,
  subtotal: 0,
  shippingCost: 0,
  discount: undefined,
  provider: 'local',
  orderState: {
    status: 'initializing'
  }
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setURLS: (state, action: PayloadAction<{ success: string; cancel: string; source: string }>) => {
      state.sourceUrl = action.payload.source;
      state.successUrl = action.payload.success;
      state.cancelUrl = action.payload.cancel;
    },
    setTransactionId: (state, action: PayloadAction<string>) => {
      state.transactionId = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      /* ---- EXTERNAL: Pay with Stripe ---- */
      .addCase(payWithStripe.fulfilled, (state, action) => {
        state.paymentId = action.payload.paymentId;
      })

      /* ---- Get Eligo Order ---- */
      .addCase(getEligoOrder.fulfilled, (state, action) => {
        state.provider = 'local';

        // const constructedData = constructTempCartData(action.payload);

        // state.total = constructedData.total;
        // state.cartData = constructedData.items;
        // state.subtotal = constructedData.subtotal;

        // TODO: temp disabled until a solution is found for Tie configurator
        state.itemCount = action.payload.items.reduce((acc: number, product: CartProduct) => acc + product.qty, 0);
        state.total = action.payload.total;
        state.subtotal = action.payload.subtotal;
        state.shippingCost = action.payload.shippingCost;
        if (action.payload?.discount) {
          state.discount = action.payload.discount;
        };

        if (action.payload.status === orderStatus.CREATED) {
          state.cartData = action.payload.items;
          state.orderState = { status: 'initialized' };
        };

        if (action.payload.status === orderStatus.PAID) state.orderState = { status: 'error', type: orderStatus.PAID };
        if (action.payload.status === orderStatus.EXPIRED) state.orderState = { status: 'error', type: orderStatus.EXPIRED };
        if (action.payload.status === orderStatus.FULFILLED) state.orderState = { status: 'error', type: orderStatus.FULFILLED };
      })
      .addCase(getEligoOrder.rejected, (state) => {
        state.orderState = { status: 'error', type: 'generic' };
      })
  }
});
export const { setURLS, setTransactionId } = cartSlice.actions;
export default cartSlice.reducer;