import React from 'react'

interface PropTypes {
  children?: React.ReactNode;
}
const PanelTitle: React.FC<PropTypes> = ({ children }) => {
  return (
    <h1 className="mx-1 whitespace-nowrap text-xs">
      {children}
    </h1>
  )
}
export default PanelTitle;