import { createAsyncThunk } from "@reduxjs/toolkit";
import type { AppDispatch, RootState } from ".";

export type AsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
}

export const createAppAsyncThunk = createAsyncThunk.withTypes<AsyncThunkConfig>();