import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import Button from '../../components/Button';
import Panel from '../../components/Panel';
import PanelHeader from '../../components/Panel/Header';
import PanelTitle from '../../components/Panel/Title';
import Typography from '../../components/Typography';
import { formSteps } from '../../constants/form';
import { paymentMethods } from '../../constants/payment';
import { useAppSelector } from '../../store';
import { setStep } from '../../store/features/form';

const PaymentInfoView: React.FC = () => {
  const method = useAppSelector((state) => state.payment.method);
  const dispatch = useDispatch();

  const handleSubmit = React.useCallback((e: React.MouseEvent): void => {
    e.preventDefault();
    dispatch(setStep(formSteps.SUMMARY));
  }, [dispatch]);

  const handleBack = React.useCallback(() => {
    dispatch(setStep(formSteps.SHIPPING))
  }, [dispatch]);

  return (
    <div id="payment-info-form">

      <Panel
        header={<PanelHeader
          title={<PanelTitle>
            <Typography text="PANEL_TITLES.PAYMENT_INFORMATION" />
          </PanelTitle>}
        />}
        fields={
          <div className="flex flex-col md:flex-row md:divide-x text-sm">


            {/* TODO: investigate expanding Typography component to label element */}
            <div className="mb-4 md:pr-4">
              <input id="creditCardRadio" className="mr-2" type="radio" name="paymentMethod" value={paymentMethods.CREDITCARD} defaultChecked={method === 'credit_card' ? true : false} />
              <label htmlFor="creditCardRadio">
                <Typography text="PAYMENT_METHODS.CREDIT_CARD" />
              </label>
            </div>

            <div className="mb-4 md:px-4">
              <input id="paypalRadio" className="mr-2" type="radio" name="paymentMethod" value={paymentMethods.PAYPAL} disabled />

              <label className="text-corduroy-400 align-middle min-w-max" htmlFor="paypalRadio">
                <Typography text="PAYMENT_METHODS.PAYPAL" />
              </label>
            </div>

            <div className="mb-4 md:pl-4">
              <input id="wireTransferRadio" className="mr-2" type="radio" name="paymentMethod" value={paymentMethods.WIRETRANSFER} disabled />
              <label className="text-corduroy-400 align-middle min-w-max" htmlFor="wireTransferRadio">
                <Typography text="PAYMENT_METHODS.WIRE_TRANSFER" />
              </label>
            </div>
          </div>}
      />

      <div className="flex justify-between mt-20">
        <Button className="back" onClick={handleBack}>
          <BiArrowBack className="mr-2 text-lg" />
          <Typography text="BUTTON.BACK" />

        </Button>
        <Button className="next" onClick={handleSubmit}>
          <Typography text="BUTTON.NEXT" />
          <BiArrowBack className="ml-2 text-lg rotate-180" />
        </Button>
      </div>

    </div>
  )
}
export default PaymentInfoView;