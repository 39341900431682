import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { formSteps } from "../../constants/form";
import { initialShippingInfo } from "../../constants/home";
import { constructEmail } from "../../helpers/user";
import { defaultLang } from "../../i18n";
import { FormState, FormSteps, ShippingDetails } from "../../models/ShippingDetails";
import { createAppAsyncThunk } from "../extras";
import { getEligoOrder } from "./cart";

export const getCountries = createAppAsyncThunk(
  "form/getCountries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.EligoService.getCountries();
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
)

export const getProvinces = createAppAsyncThunk(
  "form/getProvinces",
  async ({ countryCode }: { countryCode: string }, { rejectWithValue }) => {
    try {
      const response = await api.EligoService.getProvinces(countryCode);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
)

export const updateEligoShippingInfo = createAppAsyncThunk(
  "form/eligo/updateShippingInfo",
  async ({ orderId, payload }: { orderId: string, payload: ShippingDetails }, { rejectWithValue }) => {
    try {
      const response = await api.EligoService.updateShippingInfo({ orderId, payload });
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
)

const initialState: FormState = {
  step: formSteps.SHIPPING,
  countryOptions: [],
  provinceOptions: [],
  shippingDetails: initialShippingInfo,
  needsMailUpdate: false
}

export const formDataSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setShippingDetails: (state, action: any) => {
      state.shippingDetails = { ...action.payload };
    },
    setStep: (state, action: PayloadAction<FormSteps>) => {
      state.step = action.payload;
    },
    initWizard: (state, action) => {
      state = action.payload || initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      /* ---- EXTERNAL: Get Eligo Order ---- */
      .addCase(getEligoOrder.fulfilled, (state, action) => {
        if (action.payload?.shippingInfo) state.shippingDetails = action.payload.shippingInfo;
        // NOTE: This is a fallback for old profiles / until the profile model is updated 
        else if (action.payload?.customerId) {
          state.shippingDetails.firstname = action.payload?.customerId?.firstname || "";
          state.shippingDetails.lastname = action.payload?.customerId?.lastname || "";
          const mail = action.payload?.customerId?.userId?.identities?.find((identity: any) => identity.identityId)?.identityId || "";
          state.shippingDetails.email = constructEmail(mail);
          state.shippingDetails.telephone = action.payload?.customerId?.telephone || "";
          state.needsMailUpdate = !!state.shippingDetails.email;
          // TODO: if the user updates the shipping details, then refreshed the app, the call before stripe
          // that depends on this value, will not fire (currently in hook usePay)
          // performing this call before updating the orders shipping info should suffice 
        }
      })

      /* ---- Update Order ---- */
      .addCase(updateEligoShippingInfo.fulfilled, (state, action) => {
        if (action.payload?.shippingInfo)
          state.shippingDetails = action.payload.shippingInfo;
        state.step = formSteps.PAYMENT;
        state.needsMailUpdate = false;
      })

      /* ---- Get Provinces ---- */
      .addCase(getProvinces.fulfilled, (state, action) => {
        state.provinceOptions = action.payload;
      })
      .addCase(getProvinces.rejected, (state) => {
        state.provinceOptions = [];
      })

      /* ---- Get Countries ---- */
      .addCase(getCountries.fulfilled, (state, action) => {

        const options = action.payload.filter((country) => !!country.full_name_locale); // Note: remove empty countries
        const langAbbr = defaultLang.toUpperCase();
        state.countryOptions = [
          ...options.filter((country) => country.id === langAbbr),
          ...options.filter((country) => country.id !== langAbbr)
        ];
      })
      .addCase(getCountries.rejected, (state) => {
        state.countryOptions = [];
      })
  }
});
export const { setShippingDetails, setStep, initWizard } = formDataSlice.actions;
export default formDataSlice.reducer;