import React from 'react';

interface PropTypes {
  title?: React.ReactNode;
  icon?: React.ReactNode;
  actionable?: React.ReactNode;
}
const PanelHeader: React.FC<PropTypes> = ({ icon, title, actionable }) => {
  return (
    <div className="flex mb-3">
      <div className="flex my-auto">
        {icon}
        {title}
        {actionable}
      </div>
      <hr className="border-black" />
    </div>
  )
}
export default PanelHeader;