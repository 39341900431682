import React from 'react';
import { formatPrice } from '../../helpers/product';
import { CartProduct } from '../../models/Product';
import SkeletonImage from '../Image/Skeleton';

interface PropTypes {
  product: CartProduct;
}

const CartItem: React.FC<PropTypes> = ({ product }) => {
  return (
    <div className="flex py-5 border-b-corduroy-500 border-b-[1px] font-light">

      <SkeletonImage
        height="80"
        width="80"
        loading="lazy"
        className="max-h-20 max-w-20 min-w-20 min-h-20 w-auto h-auto bg-contain"
        skeletonClassName="h-20 w-20"
        src={product.details.product.thumbnail.url}
        alt={product.details.product.name}
      />

      <p className="break-all mx-3 my-auto max-w-[50%]">{product.details.product.name}</p>

      <div className="my-auto ml-auto">
        <p className="font-semibold">{formatPrice(product.price * product.qty)}</p>
        <p className="whitespace-nowrap">{product.qty}{" x "}{formatPrice(product.price)}</p>
      </div>

    </div>
  )
}
export default CartItem;