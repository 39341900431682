export const cartStatus = Object.freeze({
  PAID: 'paid',
  CREATED: 'created',
  EXPIRED: 'expired',
  PROCESSING: 'processing'
})

export const discountTypes = Object.freeze({
  FIXED: 'fixed',
  PERCENT: 'percentage'
})

export const cartProviders = Object.freeze({
  LOCAL: 'local'
})