import { FormikProps } from 'formik';
import React from 'react';
import { BiEditAlt } from 'react-icons/bi';
import * as yup from 'yup';
import FormActions from '../../components/Forms/FormikActions';
import FormikForm from '../../components/Forms/FormikForm';
import { TextAreaInput } from '../../components/Forms/FormikInputs';
import Panel from '../../components/Panel';
import PanelHeader from '../../components/Panel/Header';
import PanelIcon from '../../components/Panel/Icon';
import PanelTitle from '../../components/Panel/Title';
import ShippingInfoFields from '../../components/ShippingInfo/Address';
import CustomerFields from '../../components/ShippingInfo/Customer';
import { ShippingInfoModel } from '../../constants/home';
import { constructCountrydata, constructProvincedata } from '../../helpers/form';
import { useDetailsSubmit } from '../../hooks/Eligo/useDetailsSubmit';
import { ShippingDetails } from '../../models/ShippingDetails';
import { useAppDispatch, useAppSelector } from '../../store';
import { getProvinces } from '../../store/features/form';
import Typography from '../../components/Typography';

const validationSchema = yup.object().shape({
  firstname: yup.string().required('FORM.VALIDATION.REQUIRED.FIRSTNAME').matches(/^([^0-9]*)$/, "FORM.VALIDATION.INVALID.FIRSTNAME"),
  lastname: yup.string().required('FORM.VALIDATION.REQUIRED.LASTNAME').matches(/^([^0-9]*)$/, "FORM.VALIDATION.INVALID.LASTNAME"),
  email: yup.string().required('FORM.VALIDATION.REQUIRED.EMAIL').email("FORM.VALIDATION.INVALID.EMAIL"),
  telephone: yup.string().required('FORM.VALIDATION.REQUIRED.PHONE'),
  address: yup.object({
    hasStates: yup.boolean(),
    country: yup.string().required('FORM.VALIDATION.REQUIRED.COUNTRY'),
    province: yup.string().when("hasStates", {
      is: true,
      then: yup.string().required('FORM.VALIDATION.REQUIRED.PROVINCE')
    }),
    city: yup.string().required('FORM.VALIDATION.REQUIRED.CITY'),
    street: yup.string().required('FORM.VALIDATION.REQUIRED.STREET'),
    street2: yup.string().notRequired(),
    zipcode: yup.string().required('FORM.VALIDATION.REQUIRED.ZIPCODE'),
  }),
  notes: yup.string().notRequired()
});

const ShippingInfoView: React.FC = () => {
  const formRef = React.useRef<FormikProps<ShippingDetails & { hasStates: boolean }>>(null);
  const { shippingDetails, provinceOptions, countryOptions } = useAppSelector((state) => state.form);
  const dispatch = useAppDispatch();
  const updateEligoOrder = useDetailsSubmit();

  const initialFormData = React.useMemo(() => ({
    ...ShippingInfoModel,
    ...shippingDetails
  }), [shippingDetails]);

  const handleCountryChange = React.useCallback((countryCode: any) => {
    if (countryCode === formRef.current?.values.address?.country) return;
    dispatch(getProvinces({ countryCode })).unwrap()
      .then((res) => {
        if (formRef.current) {
          formRef.current.setFieldValue('address.province', "");
          formRef.current.setFieldValue('address.hasStates', res.length > 0 || false);
        }
      });
  }, [dispatch]);

  const handleSubmit = React.useCallback((formData: ShippingDetails & { address: { hasStates?: boolean } }) => {
    delete formData.address?.hasStates;
    updateEligoOrder({ payload: formData });
  }, [updateEligoOrder]);

  const availableCountries = React.useMemo(() => constructCountrydata(countryOptions), [countryOptions]);
  const availableProvinces = React.useMemo(() => constructProvincedata(provinceOptions), [provinceOptions]);

  return (
    <FormikForm
      innerRef={formRef}
      initialValues={initialFormData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>

      <Panel
        header={<PanelHeader
          title={<PanelTitle>
            <Typography text={"PANEL_TITLES.CUSTOMER_INFORMATION"} />
          </PanelTitle>}
        />}
        fields={
          <CustomerFields />
        }
      />

      <Panel
        header={<PanelHeader
          title={<PanelTitle>
            <Typography text={"PANEL_TITLES.SHIPPING_INFORMATION"} />
          </PanelTitle>}
        />}
        fields={
          <ShippingInfoFields
            countryOptions={availableCountries}
            provinceOptions={availableProvinces}
            onCountryChange={handleCountryChange}
          />
        }
      />


      <div className="">
        <Panel
          header={<PanelHeader
            title={<PanelTitle>
              <Typography text={"PANEL_TITLES.COURIER_NOTES"} />
            </PanelTitle>}
            icon={<PanelIcon>
              <BiEditAlt />
            </PanelIcon>}
          />}
          fields={
            <div className="grid grid-cols-1 md:grid-cols-2">
              <TextAreaInput
                name="notes"
                placeholder={"FORM.PLACEHOLDER.NOTES"}
                maxLength={500}
                className="!mb-0 !pb-0"
              />
              <FormActions submitBtnClass="md:ml-auto mt-5 md:mt-0 md:w-[unset] w-full" />
            </div>
          }
        />


      </div>

    </FormikForm >
  )
}
export default ShippingInfoView;