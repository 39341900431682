import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isValidUrl } from '../../../helpers/utils';
import { useAppDispatch } from '../../../store';
import { setURLS } from '../../../store/features/cart';
import Main from './Main';

// NOTE: This component acts as query-param guard;
const EligoProviderEntryPoint: React.FC = () => {
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const finalSearch = search.substring(search.lastIndexOf("?") + 1); // NOTE: take params from last question mark, a.k.a. provided urls must be encoded = 1 question mark only
  const successUrl = React.useMemo(() => { return new URLSearchParams(finalSearch).get('successUrl') }, [finalSearch]);
  const cancelUrl = React.useMemo(() => { return new URLSearchParams(finalSearch).get('cancelUrl') }, [finalSearch]);

  const isValid = React.useMemo(() => !!(successUrl && isValidUrl(successUrl) && cancelUrl && isValidUrl(cancelUrl)), [successUrl, cancelUrl])

  React.useEffect(() => {
    if (isValid) {
      let domain = new URL(successUrl!);
      dispatch(setURLS({
        success: successUrl!,
        cancel: cancelUrl!,
        source: domain.origin
      }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return isValid ? <Main /> : <Navigate to="/" replace />
}
export default EligoProviderEntryPoint;