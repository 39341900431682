import { createSlice } from "@reduxjs/toolkit";
import * as jose from 'jose';
import api from "../../api";
import type { AuthState } from "../../models/auth";
import type { ProviderLoginPayload } from "../../models/providers";
import { createAppAsyncThunk } from "../extras";
import { setTransactionId } from "./cart";

export const loginUser = createAppAsyncThunk(
  "auth/loginUser",
  async (payload: ProviderLoginPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.EligoService.loginUser(payload);

      const decodedToken: any = jose.decodeJwt(response);
      const userId: string = decodedToken?.user?.id || "";
      const orderId: string = decodedToken?.orderId;
      dispatch(setTransactionId(orderId));

      return { token: response, userId }
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
);

export const updateUser = createAppAsyncThunk(
  "auth/updateuser",
  async (payload: { email: string }, { rejectWithValue, getState }) => {
    try {
      const userId = (getState() as any)?.auth.userId;
      const response = await api.EligoService.updateUser(userId, payload);
      return response
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
)

const initialState: AuthState = {
  accessToken: "",
  userId: ""
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /* ---- Login User ---- */
      .addCase(loginUser.fulfilled, (state, action) => {
        state.accessToken = action.payload.token;
        state.userId = action.payload.userId;
      })
  }
})
export default authSlice.reducer