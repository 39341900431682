import classnames from "classnames";
import { FormikProps, useFormikContext } from 'formik';
import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { ShippingDetails } from "../../models/ShippingDetails";
import Button from "../Button";
import Typography from "../Typography";

interface PropTypes {
  className?: string;
  submitBtnType?: "submit" | "button";
  cancelBtnClass?: string;
  submitBtnClass?: string;
  onCancel?: (e: any) => void;
  onSubmit?: (e: any) => void;
}

const FormActions: React.FC<PropTypes> = ({
  className,
  cancelBtnClass = "",
  submitBtnClass = "",
  submitBtnType = "submit",
  onCancel,
  onSubmit,
}) => {
  const formik = useFormikContext<FormikProps<ShippingDetails & { hasStates: boolean }>>();

  return (
    <div className={classnames("flex justify-between mt-auto", className)}>
      {onCancel ? <Button
        onClick={onCancel}
        className={classnames("back", cancelBtnClass)}
      >
        <BiArrowBack className="mr-2 text-lg" />
        <Typography text="BUTTON.BACK" />
      </Button> : null}

      <Button
        type={submitBtnType}
        onClick={onSubmit}
        className={classnames("next", submitBtnClass)}
        disabled={!formik.isValid || formik.isSubmitting}
      >
        <Typography text="BUTTON.NEXT" />
        <BiArrowBack className="ml-2 text-lg rotate-180" />
      </Button>
    </div>
  );
};

export default FormActions;
