import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { LoadingSpinner } from './components/LoadingSpinner';
import { MainRoutes } from './routes/routes';
import ReactGa from "react-ga4";
import { analytics } from './common/appSettings';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

if (analytics.id) {
  ReactGa.initialize(analytics.id);
}

const App: React.FC = () => {

  useEffect(() => {
    if (analytics.id) {
      ReactGa.send({ hitType: "pageview", path: window.location.pathname + window.location.search });
    }
  }, []);

  return (
    <Suspense fallback='loading...'>
      <div className="w-full min-h-screen flex flex-col">
        <BrowserRouter>
          <MainRoutes />
        </BrowserRouter>
      </div>
      <LoadingSpinner />
      <ToastContainer
        position="bottom-right"
        limit={4}
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick={true}
        draggable={false}
        pauseOnHover={false}
        pauseOnFocusLoss={true}
        theme="colored"
      />
    </Suspense>
  );
}
export default App;
