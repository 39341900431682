import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiArrowBack, BiCreditCard, BiEditAlt, BiPackage } from 'react-icons/bi';
import { shallowEqual } from 'react-redux';
import { toast } from 'react-toastify';
import CartItem from '../../components/Cart/Item';
import Panel from '../../components/Panel';
import PanelActionable from '../../components/Panel/Actionable';
import PanelHeader from '../../components/Panel/Header';
import PanelIcon from '../../components/Panel/Icon';
import PanelTitle from '../../components/Panel/Title';
import Typography from '../../components/Typography';
import { discountTypes } from '../../constants/cart';
import { formSteps } from '../../constants/form';
import { paymentMethods } from '../../constants/payment';
import { formatPrice } from '../../helpers/product';
import { usePay } from '../../hooks/usePay';
import { useAppDispatch, useAppSelector } from '../../store';
import { setStep } from '../../store/features/form';
import SummaryShippingVeritifation from './ShippingVerification';

const SummaryInfo: React.FC = () => {
  const cartData = useAppSelector((state) => state.cart.cartData, shallowEqual);
  const shippingCost = useAppSelector((state) => state.cart.shippingCost);
  const paymentMethod = useAppSelector((state) => state.payment.method);
  const itemCount = useAppSelector((state) => state.cart.itemCount);
  const discount = useAppSelector((state) => state.cart.discount);
  const subtotal = useAppSelector((state) => state.cart.subtotal);
  const total = useAppSelector((state) => state.cart.total);

  const { makeStripePayment } = usePay();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const hasProducts = cartData.length > 0;

  const handleEdit = React.useCallback(() => dispatch(setStep(formSteps.SHIPPING)), [dispatch]);
  const handleBack = React.useCallback(() => dispatch(setStep(formSteps.PAYMENT)), [dispatch]);

  const SubmitHandler = React.useCallback((e: React.MouseEvent) => {
    e.preventDefault();

    if (!hasProducts) return;

    switch (paymentMethod) {
      case paymentMethods.CREDITCARD:
        makeStripePayment();
        break;

      case paymentMethods.PAYPAL:
        break;

      case paymentMethods.WIRETRANSFER:
        break;
      default:
        toast.error(t('ERROR.PAYMENT.INVALID_METHOD'));
        break;
    }
  }, [makeStripePayment, t, hasProducts, paymentMethod]);

  return (
    <form className="flex flex-col">

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Panel
          header={<PanelHeader
            title={<PanelTitle>
              <Typography text="PANEL_TITLES.SHIPPING_INFORMATION" />
            </PanelTitle>}
            actionable={<PanelActionable onClick={handleEdit}>
              <BiEditAlt className="text-secondary-500" />
            </PanelActionable>}
            icon={<PanelIcon>
              <BiPackage />
            </PanelIcon>}
          />}
          fields={<SummaryShippingVeritifation />}
        />

        {paymentMethod === 'wire_transfer' && <Panel
          header={<PanelHeader
            title={<PanelTitle>
              <Typography text="PANEL_TITLES.PAYMENT_INFORMATION" />
            </PanelTitle>}
            actionable={<PanelActionable onClick={handleBack}>
              <BiEditAlt className="text-secondary-500" />
            </PanelActionable>}
            icon={<PanelIcon>
              <BiCreditCard />
            </PanelIcon>}
          />}
          fields={<SummaryShippingVeritifation />}
        />}


        <div id="cart-summary">
          <Typography text="LABELS.YOUR_CART" Variant='h1' className="my-3 text-xl" />

          {/* TODO: translatable string with value */}
          <span className="font-light">{itemCount}{" "}<Typography text="LABELS.ITEMS" /></span>

          {cartData.map((product) => <CartItem key={product._id} product={product} />)}


          <div className="flex flex-col">

            <Typography text="LABELS.TOTAL" Variant='h1' className="text-xl mt-3 mb-5 font-medium" />

            <div className="cart-cost-item">
              <Typography text="LABELS.SUB_TOTAL" Variant="span" />
              <span>{formatPrice(subtotal)}</span>
            </div>

            <div className="cart-cost-item">
              <Typography text="LABELS.SHIPPING" Variant="span" />
              <span>{shippingCost === 0 ? t('LABELS.FREE') : <React.Fragment>{formatPrice(shippingCost)}</React.Fragment>}</span>
            </div>

            {discount && <div className="cart-cost-item">
              <Typography text="LABELS.DISCOUNT" Variant="span" />
              <span>{discount.type === discountTypes.FIXED ? `${formatPrice(discount.value)}` : `${formatPrice(subtotal - total)}`}</span>
            </div>}

            <hr className="my-5" />

            <div id="cart-total" className="flex justify-between font-medium text-base mb-2">
              <Typography text="LABELS.TOTAL" Variant="span" />
              <span>{formatPrice(total)}</span>
            </div>

          </div>

        </div>

      </div>

      <div className="flex justify-between mt-20">
        <button onClick={handleBack} className="btn back">
          <BiArrowBack className="mr-2 text-lg" />
          <Typography text="BUTTON.BACK" />
        </button>
        <button type="submit" onClick={SubmitHandler} className={`btn success`} disabled={!hasProducts}>
          <Typography text="BUTTON.SUBMIT" />
          <BiArrowBack className="ml-2 text-lg rotate-180" />
        </button>
      </div>


    </form>
  )
}
export default SummaryInfo;