declare var window: Partial<
  Window &
  typeof globalThis & {
    _env_: any;
  }
>;

/* TBD: use .env file to set the env variables */
const {
  REACT_APP_API_PROTOCOL,
  REACT_APP_API_HOST,
  REACT_APP_API_PORT,
  REACT_APP_API_BASEPATH,
  REACT_APP_ID,
  REACT_APP_NAME,
  REACT_APP_VERSION,
  REACT_APP_PAYMENT_SANDBOX,
  REACT_APP_ANALYTICS_ID,
  REACT_APP_GUEST_MAIL_LENGTH,
  REACT_APP_GUEST_MAIL_SUFFIX
} = window._env_;

// API domain
const api = {
  protocol: REACT_APP_API_PROTOCOL,
  host: REACT_APP_API_HOST,
  port: REACT_APP_API_PORT,
  basePath: REACT_APP_API_BASEPATH,
};

const applicationId = REACT_APP_ID;

const applicationSandbox = REACT_APP_PAYMENT_SANDBOX;
const analytics = {
  id: REACT_APP_ANALYTICS_ID
};

const defaultGuestMailLength = 64;
const defaultGuestMailSuffix = "eligo-guest.com";

const guestMailLength = Number(REACT_APP_GUEST_MAIL_LENGTH) || defaultGuestMailLength;
const guestMailSuffix = REACT_APP_GUEST_MAIL_SUFFIX || defaultGuestMailSuffix;

const applicationCopyright = [
  "©",
  new Date().getFullYear(),
  ["-", REACT_APP_NAME || "PLATFORM"].join(""),
  ["V", REACT_APP_VERSION].join(""),
].join(" ");

export { api, applicationId, applicationCopyright, applicationSandbox, analytics, guestMailLength, guestMailSuffix };
