import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import GenericError from '../components/GenericError';
import { ProviderLoginPayload } from '../models/providers';
import { useAppDispatch, useAppSelector } from '../store';
import { loginUser } from '../store/features/auth';

const AuthLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const { transactionId } = useParams();
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const [error, setError] = React.useState<'paid' | 'fulfilled' | 'expired' | 'generic' | undefined>(undefined);

  React.useEffect(() => {
    if (transactionId) {
      const loadData = async (): Promise<void> => {
        const loginPayload: ProviderLoginPayload = {
          username: transactionId,
          provider: "eligo",
        }
        await dispatch(loginUser(loginPayload)).unwrap()
          .catch(() => {
            setError('generic');
          })
      }
      loadData();
    } else {
      setError('generic');
    }
  }, [transactionId, dispatch]);

  if (error) return <GenericError error={error} />;
  if (!accessToken) return null;
  return <Outlet />;

}
export default AuthLayout;