export const config = {
  languages: [
    {
      code: 'en',
      name: 'English',
      native: 'English',
      flag: 'assets/images/flag/en.svg'
    },
    {
      code: 'it',
      name: 'Italian',
      native: 'Italiano',
      flag: 'assets/images/flag/it.svg'
    }
  ],
}