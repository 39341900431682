import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import type { StripePaymentPayload } from "../../api/EligoService";
import { paymentMethods } from "../../constants/payment";
import type { PaymentMethods, PaymentState } from "../../models/payment";
import { createAppAsyncThunk } from "../extras";

export const payWithStripe = createAppAsyncThunk(
  "payment/stripe",
  async (payload: StripePaymentPayload, { rejectWithValue }) => {
    try {
      const response = await api.EligoService.proceedToStripe(payload);
      return response;
    } catch (rejected: any) {
      return rejectWithValue(rejected?.response?.data?.error || rejected)
    }
  }
);
// export const payWithWireTransfer = createAsyncThunk(
//   "payment/wireTransfer",
//   async (payload: WireTransferPaymentPayload, { rejectWithValue})=> {
//     try {
//       const response = await api.EligoService.proceedToWireTransfer(payload);
//       return response;
//     } catch (rejected) {
//       return rejectWithValue(rejected?.response?.data?.error || rejected)
//     }
//   }
// )
// #endregion

const initialState: PaymentState = {
  method: paymentMethods.CREDITCARD,
}

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentMethod: (state, action: PayloadAction<PaymentMethods>) => {
      state.method = action.payload;
    }
  },
});
export const { setPaymentMethod } = paymentSlice.actions;
export default paymentSlice.reducer;