import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./locales/en.json";
import translationIT from "./locales/it.json";

const resources = {
  en: { translation: translationEN },
  it: { translation: translationIT },
};
const DETECTION_OPTIONS = {
  order: ['querystring', 'localStorage', 'navigator'],
  caches: ['localStorage']
};
export const defaultLang: string = 'it';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: DETECTION_OPTIONS,
    resources,
    supportedLngs: ['en', 'it'],
    fallbackLng: defaultLang,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;