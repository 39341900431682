import React from 'react'

interface PropTypes {
  children?: React.ReactNode;
}
const PanelIcon: React.FC<PropTypes> = ({ children }) => {
  return (
    <div className="mr-1 text-lg">
      {children}
    </div>
  )
}
export default PanelIcon;