import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import { PersistConfig, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import cart from './features/cart';
import form from "./features/form";
import payment from './features/payment';
import auth from './features/auth';
import loading from './features/loading';
import { CartState } from "../models/cart";
import tenancy from "./features/tenancy";


const persistConfig: PersistConfig<any> = {
  key: 'eligo-pay-',
  storage
}

export default combineReducers({
  cart: persistReducer<CartState, AnyAction>({ ...persistConfig, whitelist: ['cancelUrl', 'successUrl', 'sourceUrl', 'provider'], key: persistConfig.key + 'cart' }, cart),
  form,
  payment,
  auth,
  loading,
  tenancy
})