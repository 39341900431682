import classnames from 'classnames';
import React from 'react';
import { useAppSelector } from '../../store';
import SkeletonImage from '../Image/Skeleton';

interface PropTypes {
  className?: string;
  skeletonClassName?: string;
}

const CompanyLogo: React.FC<PropTypes> = ({ className, skeletonClassName }) => {
  const logo = useAppSelector((state) => state.tenancy.logo);
  const companyName = useAppSelector((state) => state.tenancy.companyName);

  return (
    <SkeletonImage
      src={logo}
      alt={companyName}
      height="80" width="80"
      className={classnames(className)}
      skeletonClassName={classnames(skeletonClassName)}
    />
  )
}
export default CompanyLogo;