import React from 'react';

interface PropTypes {
  header?: React.ReactNode;
  fields?: React.ReactNode;
}

const Panel: React.FC<PropTypes & React.HTMLAttributes<HTMLDivElement>> = ({ header, fields, className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      {header}
      {fields}
    </div>
  )
}
export default Panel;