import React from 'react';
import { I18nextProvider } from 'react-i18next';
import './index.css';
import i18n from './i18n';
import App from './App';
import { Provider } from 'react-redux';
import store, { persistor } from './store/index';
import { PersistGate } from 'redux-persist/integration/react';
import { createRoot } from "react-dom/client";
import { injectStore } from './api/helpers';
import { HelmetProvider } from "react-helmet-async";
import './assets/scss/index.scss';
import './index.scss'

const container = document.getElementById('root');
const root = createRoot(container!);

injectStore(store);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);
