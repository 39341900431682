import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretDown } from 'react-icons/fa';
import { Menu } from '@headlessui/react';
import { config } from '../config';

const LanguageSwitch: React.FC = () => {
  const { i18n } = useTranslation();
  const [nativeLabel, setNativeLabel] = React.useState<string>('');

  React.useEffect(() => {
    setNativeLabel(config.languages.find(lang => { return lang.code === i18n.language })!.native);
  }, [i18n.language])

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="inline-flex justify-center rounded border border-corduroy-300 px-2  py-1 bg-white hover:bg-corduroy-100 focus:outline-none ">
        <img className="w-4 h-4 my-auto" src={`/assets/images/flags/${i18n.language}.svg`} alt={nativeLabel}></img>
        <span className="px-2">{nativeLabel}</span>
        <FaCaretDown className="my-auto" />
      </Menu.Button>
      <Menu.Items className="bottom-8 absolute my-2 w-full rounded shadow bg-white ring-1  ring-opacity-5 divide-y divide-corduroy-100 focus:outline-none">
        {config.languages.map((lang: { code: string, name: string, native: string, flag: string }, index: number) =>
        (<Menu.Item key={`lang-${index}`}>
          {({ active }) => (
            <button type="button" onClick={() => i18n.changeLanguage(lang.code)}
              className={`flex items-center w-full px-2 py-1 text-corduroy-700 ${active ? 'bg-corduroy-200' : ''}`}>
              <img className="w-4 h-4 my-auto" src={`/assets/images/flags/${lang.code}.svg`} alt={lang.native} ></img>
              <span className="px-2">{lang.native}</span>
            </button>
          )}
        </Menu.Item>
        )
        )}
      </Menu.Items>
    </Menu>
  );
}
export default LanguageSwitch;