import React from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';
import ReactLoading from 'react-loading';
import { useAppSelector } from '../store';

export const LoadingSpinner: React.FC = () => {
  const loading = useAppSelector((state) => state.loading.loading);

  const spinner: JSX.Element = (
    <FocusLock>
      <div className="bg-transparent bg-opacity-30 fixed inset-0 z-40 bg-black">
        <div className="flex h-full flex-col items-center justify-center">
          <div className="bg-transparent h-23 w-23 border-black rounded flex flex-col items-center justify-center p-5 ">
            <div className="my-3">
              <div className="w-32 h-32">
                <ReactLoading type={'spinningBubbles'} color={'#db3559'} height={'100%'} width={'100%'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FocusLock>
  )
  return loading ? ReactDOM.createPortal(spinner, document.body) : null;
}