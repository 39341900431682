import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ShippingDetails } from '../../models/ShippingDetails';
import { useAppDispatch, useAppSelector } from '../../store';
import { updateUser } from '../../store/features/auth';
import { updateEligoShippingInfo } from '../../store/features/form';

export const useDetailsSubmit = () => {
  const transactionId = useAppSelector((state) => state.cart.transactionId);
  const needsMailUpdate = useAppSelector((state) => state.form.needsMailUpdate);
  const email = useAppSelector((state) => state.form.shippingDetails.email);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return React.useCallback(async ({ payload }: { payload: ShippingDetails }) => {
    try {
      // dispatch(setLoading(true));

      if (needsMailUpdate) await dispatch(updateUser({ email: email! })).unwrap();


      await dispatch(updateEligoShippingInfo({ orderId: transactionId, payload })).unwrap()

    } catch (error) {
      toast.error(t('ERROR.SHIPPING.GENERIC'));
    } finally {
      // dispatch(setLoading(false))
    }
  }, [dispatch, t, transactionId, needsMailUpdate, email]);




}