import React, { useState } from "react";
import classNames from "classnames";
import { logoLight } from "../../assets/index"

interface PropTypes {
  skeletonClassName?: string;
  skeletonStyle?: React.CSSProperties;
  animationEnabled?: boolean;
  fallbackUrl?: string
}
// TODO: expand with animation type and provide fadeIn  for logo
const SkeletonImage: React.FC<PropTypes & React.ImgHTMLAttributes<HTMLImageElement>> = ({
  src,
  alt,
  title,
  className,
  skeletonClassName,
  skeletonStyle,
  animationEnabled = true,
  onLoad,
  fallbackUrl = logoLight,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleLoad = () => {
    setLoading(false);
    // if (isFunction(onLoad)) onLoad();
  };

  const handleError = () => {
    setError(true);
    setLoading(false);
    // if (isFunction(onLoad)) onLoad();
  }

  React.useEffect(() => {
    setLoading(true);
    setError(false);
  }, [src]);

  return (
    <React.Fragment>
      {loading && (
        <div
          className={classNames(
            "skeleton image",
            { animation: animationEnabled },
            skeletonClassName
          )}
          style={skeletonStyle}
        />
      )}

      <img
        className={classNames(loading ? "w-[0px] block" : className)}
        src={!error ? src : fallbackUrl}
        alt={alt}
        title={title}
        onLoad={handleLoad}
        onError={handleError}
        {...rest}
      />
    </React.Fragment>
  );
};

export default SkeletonImage;
